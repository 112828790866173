import { DonutChart } from '@mantine/charts';

const DonutChartComponent = ({ data }: any) => {

    const colors = ["#FF5733", "#33FF57", "#3357FF", "#FFFF33", "#FF33FF", "#33FFFF", "#FF3366", "#6633FF", "#33FF66", "#3366FF", "#FF6633", "#66FF33", "#6633CC", "#CC33FF", "#FF33CC", "#CCFF33", "#33CCFF", "#FFCC33", "#33FFCC", "#CCFF66"]

    const donutData = data?.map((res: any, index: number) => {
        let temp: any = {}
        Object.values(res)?.forEach((val) => {
            if (typeof val === 'string') {
                temp['name'] = val
            } else {
                temp['value'] = val
                temp['color'] = colors[index % colors?.length];
            }
        })
        return temp;
    })


    return (
            <DonutChart
                data={donutData}
                mx="auto"
                withTooltip={true}
                size={300}
                thickness={50}
            />
    );
}
export default DonutChartComponent;