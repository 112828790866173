import { DataTable, useDataTableColumns } from 'mantine-datatable';
import sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import '@mantine/core/styles.css';
import { ActionIcon, TextInput, rem } from '@mantine/core';
import { companies } from '../constants';
import { IconSearch, IconX } from '@tabler/icons-react';


const MantineTable = () => {
    const [sortStatus, setSortStatus] = useState<any>({
        columnAccessor: 'name',
        direction: 'asc',
    });
    const [count, setCount] = useState(5)
    const [records, setRecords] = useState(sortBy(companies, 'name').slice(0, count));
    const [page, setPage] = useState(1);
    const [selectedRecords, setSelectedRecords] = useState<any>([]);
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('')
    

    useEffect(() => {
        const from = (page - 1) * count;
        const to = from + count;

        const data = sortBy(companies, sortStatus.columnAccessor);
        setRecords(sortStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to));
    }, [sortStatus, page]);

    useEffect(()=>{
        localStorage.removeItem('draggable-example-columns-order')
        localStorage.removeItem('draggable-example-columns-toggle')
        localStorage.removeItem('mantine-datatable-columns-width')
        localStorage.removeItem('draggable-example-columns-width')
    },[])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const response = companies.filter((res: any) => {
            return res.name.includes(e.target.value)
        })
        setRecords(response);
        setQuery(e.target.value)

    }
    const key = 'draggable-example';

    const { effectiveColumns }: any = useDataTableColumns({
        key,
        columns: [
            {
                title: "Name", accessor: 'name', draggable: true, sortable: true,
                filter: (
                    <TextInput
                        label="Employees"
                        description="Show employees whose names include the specified text"
                        placeholder="Search employees..."
                        leftSection={<IconSearch size={16} />}
                        rightSection={
                            <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={() => {
                                const from = (page - 1) * count;
                                const to = from + count;
                                setQuery('')
                                const data = sortBy(companies, sortStatus.columnAccessor);
                                setRecords(sortStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to));
                            }}>
                                <IconX size={14} />
                            </ActionIcon>
                        }
                        value={query}
                        onChange={(e) => handleChange(e)}
                    />
                ),
                filtering: query !== '',
            },
            { title: "Address", accessor: 'streetAddress', draggable: true, sortable: true},
            { title: "City", accessor: 'city', draggable: true, sortable: true  },
            { title: "Mission", accessor: 'missionStatement',draggable:true,width:"300px"},
            { title: "State", accessor: 'state',draggable:true },
        ],
    });
    

   
    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        const response = companies.filter((res: any) => {
            return res.name.includes(e.target.value)
        })
        const from = (page - 1) * count;
        const to = from + count;
        const data = sortBy(response, sortStatus.columnAccessor);
        setRecords(sortStatus.direction === 'desc' ? data.reverse().slice(from, to) : data.slice(from, to));
    }
 

    return (
        <div>
            <TextInput
                mt="md"
                rightSectionPointerEvents="none"
                rightSection={<IconSearch size={16} />}
                label="Search"
                placeholder="Search here..."
                className='w-2/6 mb-3 ml-auto'
                value={search}
                onChange={(e) => handleChangeSearch(e)}
            />

            <DataTable
                className='mantineTable'
                withTableBorder
                withColumnBorders
                withRowBorders
                storeColumnsKey={key}
                records={records}
                columns={effectiveColumns}
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                totalRecords={companies.length}
                recordsPerPage={count}
                page={page}
                onPageChange={(p) => setPage(p)}
                selectedRecords={selectedRecords}
                onSelectedRecordsChange={setSelectedRecords}
            />
        </div>
    );
}

export default MantineTable;