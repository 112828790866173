import { DataType } from 'ka-table/enums';
import { Table } from 'ka-table';
import 'ka-table/style.scss';

const dataArray = [
    {
        "column1": "column:1 row:0",
        "column2": "column:2 row:0",
        "column3": "column:3 row:0",
        "column4": "column:4 row:0",
        "id": 0
    },
    {
        "column1": "column:1 row:1",
        "column2": "column:2 row:1",
        "column3": "column:3 row:1",
        "column4": "column:4 row:1",
        "id": 1
    },
    {
        "column1": "column:1 row:2",
        "column2": "column:2 row:2",
        "column3": "column:3 row:2",
        "column4": "column:4 row:2",
        "id": 2
    },
    {
        "column1": "column:1 row:3",
        "column2": "column:2 row:3",
        "column3": "column:3 row:3",
        "column4": "column:4 row:3",
        "id": 3
    },
    {
        "column1": "column:1 row:4",
        "column2": "column:2 row:4",
        "column3": "column:3 row:4",
        "column4": "column:4 row:4",
        "id": 4
    },
    {
        "column1": "column:1 row:5",
        "column2": "column:2 row:5",
        "column3": "column:3 row:5",
        "column4": "column:4 row:5",
        "id": 5
    },
    {
        "column1": "column:1 row:6",
        "column2": "column:2 row:6",
        "column3": "column:3 row:6",
        "column4": "column:4 row:6",
        "id": 6
    },
    {
        "column1": "column:1 row:7",
        "column2": "column:2 row:7",
        "column3": "column:3 row:7",
        "column4": "column:4 row:7",
        "id": 7
    },
    {
        "column1": "column:1 row:8",
        "column2": "column:2 row:8",
        "column3": "column:3 row:8",
        "column4": "column:4 row:8",
        "id": 8
    },
    {
        "column1": "column:1 row:9",
        "column2": "column:2 row:9",
        "column3": "column:3 row:9",
        "column4": "column:4 row:9",
        "id": 9
    }
]

  
const KaTable = () => {
  return (
    <Table
      columns={[
        { key: 'column1', title: 'Column 1', dataType: DataType.String },
        { key: 'column2', title: 'Column 2', dataType: DataType.String },
        { key: 'column3', title: 'Column 3', dataType: DataType.String },
        { key: 'column4', title: 'Column 4', dataType: DataType.String },
      ]}
      data={dataArray}
    //   editingMode={EditingMode.Cell}
      rowKeyField={'id'}
    //   sortingMode={SortingMode.Single}
    />
  );
};

export default KaTable;
