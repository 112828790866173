import { AreaChart } from '@mantine/charts';
import { colors } from '../../constants';

const AreaChartComponent = ({ data, height ,color}: any) => {

  const series = Object.keys(data[0])?.map((res: any) => {
    return {
      name: res,
      color:color
    }
  })
  const seriesUpdated = series?.filter((res: any) => {
    return res.name !== Object.keys(data[0])[0]
  })

  return (
    <div>
      <AreaChart
        h={300}
        data={data}
        dataKey={Object.keys(data[0])[0]}
        series={seriesUpdated}
        curveType="natural"
        areaProps={{isAnimationActive:true}}
      />
    </div>
  );
}
export default AreaChartComponent;