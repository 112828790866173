import React, { useState } from "react"
import MaterialBarChart from "../../../components/MaterialUiCharts/BarChart";
import products from "../../../constants/dummy.json";
import { Select } from "@mantine/core";
import { MultiSelect } from '@mantine/core';
import { chartTypeOptions } from "../../../constants";

const MateriUi = () => {
    const productsList = products.products
    const [label, setLable] = useState<any>('');
    const [value, setValue] = useState<string[]>([]);
    const [chartType, setChartType] = useState<string | null>('')

    const options = Object.keys(productsList[0])?.map((res: string, index: number) => {
        return {
            value: res,
            label: res
        }
    })

    const chartData = productsList?.map((res: any) => {
        let data: any = {}
        Object.keys(res)?.map((val: any) => {
            if (val === label) {
                value?.forEach((mulVal: any) => {
                    data[label] = res[label];
                    data[mulVal] = res[mulVal];
                })
            }
        })
        return data
    })

    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-3 gap-4">
                <Select
                    label="Select Chart type"
                    placeholder="select chart type"
                    className="dropdown mb-4 mt-4"
                    data={chartTypeOptions}
                    value={chartType}
                    onChange={(_value, option) => setChartType(_value)}
                    checkIconPosition="right"
                    searchable
                    allowDeselect={false}
                />
                <Select
                    label="Select label"
                    placeholder="label should be strig type"
                    className="dropdown mb-4 mt-4"
                    data={options}
                    value={label}
                    onChange={(_value, option) => setLable(_value)}
                    checkIconPosition="right"
                    searchable
                    allowDeselect={false}

                />
                <MultiSelect
                    label="Select values"
                    placeholder="values should be number type"
                    data={options}
                    value={value}
                    onChange={setValue}
                    checkIconPosition="right"
                    searchable
                    className="mb-4 mt-4"
                    hidePickedOptions
                />
            </div>

                    <MaterialBarChart
                        data={chartData}
                        label={label}
                        value={value}
                        chartType={chartType}
                    />

        </div>
    )
};

export default MateriUi;
