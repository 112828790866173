import { PieChart } from '@mantine/charts';

const PieChartComponent = ({ data }: any) => {

    const colors = ['indigo.6', 'yellow.6', 'teal.6', 'gray.6']

    const donutData = data?.map((res: any) => {
        let temp: any = {}
        Object.values(res)?.forEach((val) => {
            if (typeof val === 'string') {
                temp['name'] = val
            } else {
                temp['value'] = val
                temp['color'] = colors[Math.floor(Math.random() * colors.length)];
            }
        })
        return temp;
    })


    return (
        <PieChart
            data={donutData}
            mx="auto"
            withTooltip={true}
            size={300}
            // h={300}
            // w={300}
        />
    );
}
export default PieChartComponent;