import MuiTable from "../../components/MuiTable";
import MantineTable from "../../components/MantineTable";
import KaTable from "../../components/KaTable";
import AntdTable from "../../components/AntdTable";
import { useState } from "react";
import { Select } from "@mantine/core";



const DataList = () => {

    const [value, setValue] = useState<string | null>('1');
    
    const options = [
        { value: '1', label: 'Mantine' },
        { value: '2', label: 'Antd design' },
        { value: '3', label: 'Material ui' },
        { value: '4', label: 'Ka-Table' },

    ]

    return (
        <div className="container mx-auto pt-4">

            <div>
                <Select
                    label="Select grid view"
                    placeholder="Pick value"
                    className="dropdown"
                    data={options}
                    value={value}
                    onChange={(_value, option) => setValue(_value)}
                />
            </div>
            {
                value === '1' &&
                <>

                    <p className="mb-2 mt-4">Mantine Table</p>
                    <MantineTable />
                </>
            }

            {
                value === '2' &&
                <>
                    <p className="mb-2 mt-4">Antd Table</p>
                    <AntdTable />
                </>
            }
            {
                value === '3' &&
                <>
                    <p className="mb-2 mt-3">Material ui Data x grid Table</p>
                    <MuiTable />
                </>
            }

            {
                value === '4' &&
                <>
                    <p className="mb-2 mt-4">Ka Table</p>
                    <KaTable />
                </>
            }

        </div>
    )
};

export default DataList;
