import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import UploadExcel from './pages/uploadExcel';
import DataList from './pages/dataList';
import Charts from './pages/charts';
import MateriUi from './pages/charts/material';
import Sidebar from './components/sidebar/sidebar';
import NavBar from './components/navBar/navBar';
import Loader from './components/loader';
import { useSelector } from 'react-redux';
import { RootState } from './service/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Suspense, lazy } from 'react';

const Reporting = lazy(() => import('./pages/reporting'));
const SummaryReport = lazy(() => import('./pages/reporting/reportTypeList/summaryReport'));
const ReportTypeList =lazy(() => import('./pages/reporting/reportTypeList'));
const ViewReports =lazy(() => import('./pages/reporting/reportTypeList/viewReports'));
const CampaignSettings = lazy(() => import('./pages/campaignSettings'));
const CampaignConfigDetails = lazy(() => import('./pages/campaignSettings/campaignConfigDetails'));
const DataManageMent = lazy(() => import('./pages/dataManageMent'));
const Configurations =  lazy(() => import('./pages/configurations'));
const Dashboard =  lazy(() => import('./pages/dashboard'));
const ViewTableReport =  lazy(() => import('./pages/reporting/reportTypeList/viewTableReport'));
const ViewData =  lazy(() => import('./pages/dataManageMent/viewData'));


function App() {
  const loader = useSelector((state: RootState) => state.static.loader);

  return (
    <>
      {/* <Sidebar /> */}
      <NavBar />
      <ToastContainer autoClose={3000} />
      {loader && <Loader />}
      <main>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/">
              <Route path='/reporting' element={<Reporting />} />
              <Route path='/reporting/report-type-list' element={<ReportTypeList />} />
              <Route path='/reporting/report-type-list/summart-report' element={<SummaryReport />} />
              <Route path='/reporting/report-type-list/:id' element={<ViewReports />} />
              <Route path='/reporting/report-type-list/:id/view' element={<ViewTableReport />} />
              <Route path='/campaign-settings' element={<CampaignSettings />} />
              <Route path='/campaign-settings/campaign-config-details' element={<CampaignConfigDetails />} />
              <Route path='/data-management' element={<DataManageMent />} />
              <Route path='/data-management/view-data' element={<ViewData />} />
              <Route path='/configurations' element={<Configurations />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/upload-excel' element={<UploadExcel />} />
              <Route path='/data-list' element={<DataList />} />
              <Route path='/chart-list' element={<Charts />} />
              <Route path='/chart-list/material-ui' element={<MateriUi />} />
              <Route index element={<Navigate to="/reporting" replace />} />
            </Route>
          </Routes>
        </Suspense>
      </main>
    </>
  );
}

export default App;
