import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const MaterialBarChart = ({ data, label, value, chartType }: any) => {

    const getKeys = data?.map((res: any) => {
        let temp: any = {};
        Object.values(res)?.forEach((val: any) => {
            if (typeof val === 'string') {
                temp[label] = res[label];
            }
        })
        let tempArray = Object.values(temp)
        return [...tempArray]
    })
    const combinedArray = [].concat(...getKeys);

    const series = Object.keys(data[0])?.map((res: any) => {
        return {
            name: res,
        }
    })
    const seriesUpdated = series?.filter((res: any) => {
        return res.name !== Object.keys(data[0])[0]
    })

    const final: any = [];
    const tempArr = value?.forEach((val: any, index: number) => {
        seriesUpdated?.forEach((ser: any) => {
            if (val === ser.name) {
                let value = data?.map((res: any) => {
                    return res[val]
                })

                final.push({ data: value, label: val, id: index })
            }
        })
    })

    const serieAreaChart = final?.map((res: any) => {
        return {
            ...res,
            area: true,
            showMark: false
        }
    })


    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

    const donutData = data?.map((res: any) => {
        let temp: any = {}
        Object.values(res)?.forEach((val) => {
            if (typeof val === 'string') {
                temp['name'] = val
            } else {
                temp['value'] = val
                temp['color'] = colors[Math.floor(Math.random() * colors.length)];
            }
        })
        return temp;
    })

    const sizing = {
        margin: { right: 5 },
        width: 500,
        height: 500,
        legend: { hidden: true },
    };
    const TOTAL = donutData?.map((item: any) => item.value).reduce((a: any, b: any) => a + b, 0);

    const getArcLabel = (params: any) => {
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };

    const StyledText = styled('text')(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 20,
      }));

    function PieCenterLabel({ children }: { children: React.ReactNode }) {
        const { width, height, left, top } = useDrawingArea();
        return (
          <StyledText x={left + width / 2} y={top + height / 2}>
            {children}
          </StyledText>
        );
      }
      const size = {
        width: 500,
        height: 500,
      };
    return (
        <div>
            {
                chartType === 'barChart' &&
                <>
                    <p className="mb-2 mt-4">Bar chart</p>
                    <BarChart
                        series={final}
                        xAxis={[{ data: combinedArray, scaleType: 'band' }]}
                        width={window.innerWidth - 100}
                        height={window.innerHeight - 200}
                    />
                </>
            }

            {
                chartType === 'lineChart' &&
                <>
                    <p className="mb-2 mt-4">Line chart</p>
                    <LineChart
                        series={final}
                        xAxis={[{ data: combinedArray, scaleType: 'band' }]}
                        width={window.innerWidth - 100}
                        height={window.innerHeight - 200}
                    />
                </>
            }

            {
                chartType === 'areaChart' &&
                <>
                    <p className="mb-2 mt-4">Area chart</p>
                    <LineChart
                        series={serieAreaChart}
                        xAxis={[{ data: combinedArray, scaleType: 'band' }]}
                        width={window.innerWidth - 100}
                        height={window.innerHeight - 200}
                    />
                </>
            }

            {
                chartType === 'pieChart' &&
                <>
                    <p className="mb-2 mt-4">Pie chart</p>
                    <PieChart
                        series={[
                            {
                                // outerRadius: 80,
                                data: donutData,
                                arcLabel: getArcLabel,
                            },
                        ]}
                        sx={{
                            [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontSize: 14,
                            },
                        }}
                        {...sizing}
                    />
                </>
            }

            {
                chartType === 'donutChart' &&
                <>
                    <p className="mb-2 mt-4">Donut chart</p>
                    <PieChart series={[{ data:donutData, innerRadius: 50 }]} {...size}>
                        <PieCenterLabel>Center</PieCenterLabel>
                    </PieChart>
                </>
            }

        </div>
    );
}
export default MaterialBarChart;