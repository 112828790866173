import { LineChart } from '@mantine/charts';
import { colors } from '../../constants';

const LineChartComponent = ({ data ,height,color}: any) => {

  const series = Object.keys(data[0])?.map((res: any) => {
    return {
      name: res,
      color:color
    }
  })
  const seriesUpdated = series?.filter((res: any) => {
    return res.name !== Object.keys(data[0])[0]
  })

  return (
    <LineChart
      h={height}
      mah={height}
      data={data}
      dataKey={Object.keys(data[0])[0]}
      series={seriesUpdated}
      curveType="linear"
      lineProps={{isAnimationActive:true}}
    />
  );
}
export default LineChartComponent;