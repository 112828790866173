import { useNavigate } from "react-router-dom";
import { NavBarElements } from "../../constants";
import { Menu } from '@mantine/core';
import ProfileIcon from "../../assets/profile.svg";
import CtrlPIcon from "../../assets/ctrlp.svg";
import SettingsIcon from "../../assets/settings.svg";
import CtrlSIcon from "../../assets/ctrls.svg";
import LogOutIcon from "../../assets/logout.svg";
import LogoutShortcutIcon from "../../assets/logoutShortcut.svg";
import SelectDownArrow from "../../assets/selectDownArrow.svg";
import SelectUpArrow from "../../assets/selectUpArrow.svg";
import { useDisclosure } from '@mantine/hooks';

const NavBar = () => {
    const navigate = useNavigate()
    const pathName = window.location?.pathname?.split("/")[1]
    const [opened, handlers] = useDisclosure(false);

    return (
        <nav className="z-50 w-full bg-white border-inherit border dark:bg-gray-900">
            <div className=" flex flex-wrap items-center justify-between mx-auto p-3">
                <div className="flex items-center">
                    <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 h-7 w-7 lg:h-8 lg:w-8 xl:w-10 xl:h-10 justify-center text-sm text-gray-500 rounded-lg  hover:bg-gray-100 outline-none ring-2 ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className="h-9 border border-slate-300 ml-3" />
                    {/* <p className="ml-3 text-[color:var(---app-blue-color)] font-bold text-sm lg:text-text-base 2xl:text-lg">Reporting</p> */}
                </div>

                <div>
                    <ul className="font-medium flex items-center flex-row md:p-0   md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                        {
                            NavBarElements.map((nav: { title: string, link: string, layout: string }, index: number) => {
                                return (
                                    <li key={index} onClick={() => navigate(nav.link)} className={`${nav.layout === pathName ? ' bg-[color:var(---app-bgLightBlue-color)] pl-3 pr-3 pt-2 pb-2 rounded-lg border border-cyan-300' : ''}`}>
                                        <p  className={`${nav.layout === pathName ? 'text-[color:var(---app-darkBlue-color)]' : 'text-[#475467]'} ` + " cursor-pointer block py-2 px-3  font-bold text-xs lg:text-sm 2xl:text-base rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"}>{nav.title}</p>
                                    </li>
                                )
                            })
                        }

                        <li>
                            <Menu shadow="md" width={200} opened={opened} onOpen={handlers.open} onClose={handlers.close}>
                                <Menu.Target>
                                    <button className="flex items-center gap-2 w-full bg-gray-50 border border-gray-300 text-[#344054] font-bold text-xs lg:text-sm 2xl:text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-5 pr-5 pt-2 pb-2">
                                        Account
                                        <img src={opened ? SelectUpArrow : SelectDownArrow} alt="dropdown" className="h-3 w-3 object-contain" />
                                    </button>
                                </Menu.Target>

                                <Menu.Dropdown >
                                    <div className="p-3">
                                        <p className="text-[color:var(---app-textlightblack-color)] text-xs xl:text-sm font-semibold">Aniket Saha</p>
                                        <p className="text-xs xl:text-sm text-[color:var(---app-textblack100-color)]">aniket@initiative.com</p>
                                    </div>
                                    <Menu.Divider />
                                    <div className="">
                                        <Menu.Item className="">
                                            <div className="flex justify-between cursor-pointer">
                                                <div className="flex items-center">
                                                    <img src={ProfileIcon} alt="ProfileIcon" className="h-4 w-4" />
                                                    <p className="ml-2 text-[color:var(---app-textlightblack-color)] text-xs xl:text-sm font-medium">View profile</p>
                                                </div>
                                                <img src={CtrlPIcon} alt="CtrlPIcon" className="h-5 w-9" />
                                            </div>
                                        </Menu.Item>
                                        <Menu.Item className="">
                                            <div className="flex justify-between cursor-pointer">
                                                <div className="flex items-center" onClick={() => navigate('/configurations')}>
                                                    <img src={SettingsIcon} alt="ProfileIcon" className="h-4 w-4" />
                                                    <p className="ml-2 text-[color:var(---app-textlightblack-color)] text-xs xl:text-sm font-medium">Configurations</p>
                                                </div>
                                                <img src={CtrlSIcon} alt="CtrlPIcon" className="h-5 w-5" />
                                            </div>
                                        </Menu.Item>
                                    </div>

                                    <Menu.Divider />
                                    <Menu.Item className="">
                                        <div className="flex justify-between cursor-pointer">
                                            <div className="flex items-center">
                                                <img src={LogOutIcon} alt="ProfileIcon" className="h-4 w-4" />
                                                <p className="ml-2 text-[color:var(---app-textlightblack-color)] text-xs xl:text-sm font-medium">Log out</p>
                                            </div>
                                            <img src={LogoutShortcutIcon} alt="CtrlPIcon" className="h-5 w-9" />
                                        </div>
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>

                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default NavBar;
