import React, { useState } from "react"
import AreaChartComponent from "../../components/MantineCharts/AreaChart";
import '@mantine/charts/styles.css';
import products from "../../constants/dummy.json";
import { Select } from "@mantine/core";
import { MultiSelect } from '@mantine/core';
import BarChartComponent from "../../components/MantineCharts/BarChart";
import LineChartComponent from "../../components/MantineCharts/LineChart";
import DonutChartComponent from "../../components/MantineCharts/DonutChart";
import PieChartComponent from "../../components/MantineCharts/PieChart";
import { chartTypeOptions } from "../../constants";


const Charts = () => {
    const productsList = products.products
    const [label, setLable] = useState<any>('');
    const [value, setValue] = useState<string[]>([]);
    const [chartType, setChartType] = useState<string | null>('')

    const options = Object.keys(productsList[0])?.map((res: string, index: number) => {
        return {
            value: res,
            label: res
        }
    })

    const chartData = productsList?.map((res: any) => {
        let data: any = {}
        Object.keys(res)?.map((val: any) => {
            if (val === label) {
                value?.forEach((mulVal: any) => {
                    data[label] = res[label];
                    data[mulVal] = res[mulVal];
                })
            }
        })
        return data
    })


    return (
        <div className="container mx-auto">
            <div className="mb-4">
                <div className="grid grid-cols-3 gap-4">
                    <Select
                        label="Select Chart type"
                        placeholder="select chart type"
                        className="dropdown mb-4 mt-4"
                        data={chartTypeOptions}
                        value={chartType}
                        onChange={(_value, option) => setChartType(_value)}
                        checkIconPosition="right"
                        searchable
                        allowDeselect={false}
                    />
                    <Select
                        label="Select label"
                        placeholder="label should be strig type"
                        className="dropdown mb-4 mt-4"
                        data={options}
                        value={label}
                        onChange={(_value, option) => setLable(_value)}
                        checkIconPosition="right"
                        searchable
                        allowDeselect={false}

                    />
                    <MultiSelect
                        label="Select values"
                        placeholder="values should be number type"
                        data={options}
                        value={value}
                        onChange={setValue}
                        checkIconPosition="right"
                        searchable
                        className="mb-4 mt-4"
                        hidePickedOptions
                    />
                </div>

                {
                    chartType === 'areaChart' &&
                    <>
                        <p className="mb-2 mt-4">Area chart</p>
                        <AreaChartComponent data={chartData} height={window.innerHeight - 200} />
                    </>
                }

                {
                    chartType === 'barChart' &&
                    <>
                        <p className="mb-2 mt-4">Bar chart</p>
                        <BarChartComponent data={chartData} height={window.innerHeight-200}/>
                    </>
                }

                {
                    chartType === 'lineChart' &&
                    <>
                        <p className="mb-2 mt-4">Line chart</p>
                        <LineChartComponent data={chartData} height={window.innerHeight - 200}/>
                    </>
                }

                {
                    chartType === 'donutChart' &&
                    <>
                        <p className="mb-2 mt-4">Donut chart</p>
                        <DonutChartComponent data={chartData} />
                    </>
                }

                {
                    chartType === 'pieChart' &&
                    <>
                        <p className="mb-2 mt-4">Pie chart</p>
                        <PieChartComponent data={chartData} />
                    </>
                }
            </div>

        </div>
    )
};

export default Charts;
