import { BarChart } from '@mantine/charts';
import { colors } from '../../constants';

const BarChartComponent = ({ data ,height,color}: any) => {
  
  const series = Object.keys(data[0])?.map((res: any) => {
    return {
      name: res,
      color: color
    }
  })
const seriesUpdated = series?.filter((res:any)=>{
  return res.name !== Object.keys(data[0])[0]
})

  return (
    <BarChart
      h={height}
      data={data}
      dataKey={Object.keys(data[0])[0]}
      series={seriesUpdated}
      tickLine="none"
      orientation='vertical'
      barProps={{ radius: 10,maxBarSize:20,isAnimationActive:true }}
    />
  );
}
export default BarChartComponent;